/* tslint:disable */
import { Page, Pageable, Slice, Sort } from 'fw-core';

import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
export interface Dictionary<V> {
    [key: string]: V;
}

export type Instant = {};
export type LocalDate = {};

export enum AdjustmentType { AMOUNT = "AMOUNT", PERCENTAGE = "PERCENTAGE" }
export enum Alert { NO_MARKET_VALUE = "NO_MARKET_VALUE", RATE_0_VS_VR_GT_35 = "RATE_0_VS_VR_GT_35", NO_KM = "NO_KM", NO_VERSION = "NO_VERSION", NO_MAKE = "NO_MAKE", NO_PLATE_NUMBER = "NO_PLATE_NUMBER", NO_DAMAGES = "NO_DAMAGES", NO_COLOR = "NO_COLOR", NO_REGISTRATION_DATE = "NO_REGISTRATION_DATE", NO_FUEL = "NO_FUEL", NO_GEAR = "NO_GEAR", NO_DOORS = "NO_DOORS", FINAL_RATE_GT_LAST_RATE = "FINAL_RATE_GT_LAST_RATE", MARKET_VALUE_LT_5000 = "MARKET_VALUE_LT_5000", OPTIONS_ADJUSTMENT_GT_4500 = "OPTIONS_ADJUSTMENT_GT_4500", B2C_GT_FINAL_RATE = "B2C_GT_FINAL_RATE", FINAL_RATE_GT_MARKET_VALUE = "FINAL_RATE_GT_MARKET_VALUE", FINANCIAL_VALUE_LT_FINAL_RATE = "FINANCIAL_VALUE_LT_FINAL_RATE", FINAL_RATE_LT_LAST_RATE = "FINAL_RATE_LT_LAST_RATE", FINAL_RATE_GT_RESIDUAL_VALUE = "FINAL_RATE_GT_RESIDUAL_VALUE" }
export enum EquipmentType { STANDARD = "STANDARD", OPTIONAL = "OPTIONAL" }
export enum ExportStatus { PROCESSING = "PROCESSING", FINISHED = "FINISHED" }
export enum ImportErrorType { ERROR = "ERROR", WARNING = "WARNING" }
export enum VehicleImportStatus { EXCEL_READ = "EXCEL_READ", EXCEL_ERROR = "EXCEL_ERROR", READY = "READY", FINISHED = "FINISHED", FAILED = "FAILED" }
export enum VehicleState { FLEET = "FLEET", STOCK = "STOCK" }
export enum VehicleType { PASSENGER = "PASSENGER", COMMERCIAL = "COMMERCIAL", OTHER = "OTHER" }

export interface AccountDTO {
    authorities?: string[];
    avatar?: FwFileDTO;
    avatarUrl?: string;
    email?: string;
    firstName?: string;
    langKey?: string;
    lastName?: string;
    login?: string;
    loginUserOriginal?: string;
    validated?: boolean;
    version?: number;
}

export interface AlertDTO {
    alert?: Alert[];
}

export interface AuditDTO {
    createdBy?: string;
    createdDate?: Instant;
    lastModifiedBy?: string;
    lastModifiedDate?: Instant;
}

export interface AuthorityDTO {
    i18n?: string;
    name?: string;
}

export interface BasicDTO {
    id?: number;
    label?: string;
    version?: number;
}

export interface ClientDTO {
    b2bPercentage?: number;
    damagesPercentage?: number;
    expenses?: number;
    franchise?: number;
    id?: number;
    name?: string;
    optionsMinimumForAdjustment?: number;
    priceKmWhenDeficit?: number;
    priceKmWhenExcess?: number;
    vatRate?: number;
}

export interface ClientFilter {
    name?: string;
}

export interface ClientListDTO {
    id?: number;
    name?: string;
}

export interface ExportFilter {
    client?: BasicDTO;
    clientId?: number;
    damagesMax?: number;
    damagesMin?: number;
    extensionDueDateFrom?: LocalDate;
    extensionDueDateFromMonth?: number;
    extensionDueDateFromYear?: number;
    extensionDueDateTo?: LocalDate;
    extensionDueDateToMonth?: number;
    extensionDueDateToYear?: number;
    finalRateMax?: number;
    finalRateMin?: number;
    finalRateVsVrAmountMax?: number;
    finalRateVsVrAmountMin?: number;
    finalRateVsVrPercentageMax?: number;
    finalRateVsVrPercentageMin?: number;
    insurance?: boolean;
    kilometersMax?: number;
    kilometersMin?: number;
    make?: string;
    model?: string;
    numberVo?: string;
    plateNumber?: string;
    platesAsList?: string[];
    registrationDateFrom?: LocalDate;
    registrationDateTo?: LocalDate;
    residualValueWithTaxesMax?: number;
    residualValueWithTaxesMin?: number;
    valuationDateFrom?: LocalDate;
    valuationDateFromAsInstant?: Instant;
    valuationDateFromMonth?: number;
    valuationDateFromYear?: number;
    valuationDateTo?: LocalDate;
    valuationDateToAsInstant?: Instant;
    valuationDateToMonth?: number;
    valuationDateToYear?: number;
    vehicleState?: VehicleState;
    vehicleType?: VehicleType;
    version?: string;
}

export interface FinalRateDTO {
    adjustmentType?: AdjustmentType;
    amountsId?: number;
    finalRate?: number;
    financialValue?: number;
    residualValueWithTaxes?: number;
    specialistAdjustment?: number;
    validated?: boolean;
    vatRate?: number;
}

export interface FleetDTO {
    body?: string;
    color?: string;
    damages?: number;
    daysFromAvailableToSale?: number;
    depreciation?: number;
    discountAverage?: number;
    engineCapacity?: number;
    extensionDueDate?: LocalDate;
    finalRateVsFinancialValue?: number;
    financialValue?: number;
    futureSixMonthsStock?: number;
    insurance?: boolean;
    kilometersAverage?: number;
    numberVo?: string;
    optionsAdjustmentAmount?: number;
    optionsAdjustmentPercent?: number;
    pff?: number;
    physicalStock?: number;
    power?: number;
    priceAccessoriesWithTaxes?: number;
    priceExtras?: number;
    rateVsResidualValuePercentage?: number;
    registrationDate?: LocalDate;
    residualValue?: number;
    salePriceAverage?: number;
    sales?: number;
    type?: VehicleType;
}

export interface FleetListDTO {
    b2cRate?: number;
    channel1?: BasicDTO;
    channel2?: BasicDTO;
    channel3?: BasicDTO;
    channel4?: BasicDTO;
    channelsSize?: number;
    clientId?: number;
    color?: string;
    doors?: number;
    estimatedOdometer?: number;
    financialValue?: number;
    fuel?: string;
    greenAlerts?: AlertDTO;
    lastValidationDate?: Instant;
    make?: string;
    marketValue?: number;
    model?: string;
    optionsAdjustment?: number;
    orangeAlerts?: AlertDTO;
    originalMarketValue?: number;
    plateNumber?: string;
    rate?: RateDTO;
    rateVsResidualValue?: number;
    redAlerts?: AlertDTO;
    residualValueWithTaxes?: number;
    selected?: boolean;
    transmission?: string;
    validationDate?: Instant;
    vatRate?: number;
    version?: string;
    vin?: string;
}

export interface FwFileDTO {
    id?: number;
    name?: string;
    temporalFileName?: string;
}

export interface ImportErrorDTO {
    error?: string;
    errorType?: ImportErrorType;
    id?: number;
    rowNumber?: number;
}

export interface KeyAndPasswordDTO {
    key?: string;
    newPassword?: string;
}

export interface LanguageDTO {
    code?: string;
    default?: boolean;
    name?: string;
    translation?: string;
}

export interface NewVehicleImportDTO {
    client?: BasicDTO;
    identifyVehicles?: boolean;
    storedFile?: FwFileDTO;
    vehicleState?: VehicleState;
}

export interface PasswordChangeDTO {
    currentPassword?: string;
    newPassword?: string;
}

export interface ProcessErrorDTO {
    error?: string;
    vin?: string;
}

export interface RateDTO {
    adjustmentType?: AdjustmentType;
    amountsId?: number;
    clientExpenses?: number;
    finalRate?: number;
    objetiveRate?: number;
    referenceRate?: number;
    specialistAdjustment?: number;
    validated?: boolean;
}

export interface RateFilter {
    client?: BasicDTO;
    clientId?: number;
    damagesMax?: number;
    damagesMin?: number;
    extensionDueDateFrom?: LocalDate;
    extensionDueDateFromMonth?: number;
    extensionDueDateFromYear?: number;
    extensionDueDateTo?: LocalDate;
    extensionDueDateToMonth?: number;
    extensionDueDateToYear?: number;
    finalRateMax?: number;
    finalRateMin?: number;
    finalRateVsVrAmountMax?: number;
    finalRateVsVrAmountMin?: number;
    finalRateVsVrPercentageMax?: number;
    finalRateVsVrPercentageMin?: number;
    insurance?: boolean;
    kilometersMax?: number;
    kilometersMin?: number;
    make?: string;
    model?: string;
    numberVo?: string;
    plateNumber?: string;
    platesAsList?: string[];
    registrationDateFrom?: LocalDate;
    registrationDateTo?: LocalDate;
    residualValueWithTaxesMax?: number;
    residualValueWithTaxesMin?: number;
    valuationDateFrom?: LocalDate;
    valuationDateFromAsInstant?: Instant;
    valuationDateFromMonth?: number;
    valuationDateFromYear?: number;
    valuationDateTo?: LocalDate;
    valuationDateToAsInstant?: Instant;
    valuationDateToMonth?: number;
    valuationDateToYear?: number;
    vehicleState?: VehicleState;
    vehicleType?: VehicleType;
    version?: string;
}

export interface RateListDTO {
    date?: Instant;
    rate?: number;
}

export interface StockDTO {
    body?: string;
    colorAdjustment?: number;
    damagesAdjustment?: number;
    daysFromAvailableToSale?: number;
    deducedKilometers?: number;
    differenceFinalRateSalesPriceAveragePercentage?: number;
    differenceLastRateFinalRateAmount?: number;
    differenceLastRateFinalRatePercentage?: number;
    differenceVrFinalRateAmount?: number;
    discountAverage?: number;
    futureSixMonthsStock?: number;
    kilometersAverage?: number;
    numberVO?: string;
    optionsAdjustment?: number;
    optionsAdjustmentAmount?: number;
    pff?: number;
    physicalStock?: number;
    power?: number;
    priceAccessoriesWithTaxes?: number;
    priceExtras?: number;
    salePriceAverage?: number;
    sales?: number;
    type?: VehicleType;
}

export interface StockListDTO {
    color?: string;
    damages?: number;
    differenceVrFinalRatePercentage?: number;
    doors?: number;
    fuel?: string;
    greenAlerts?: AlertDTO;
    lastValidationDate?: Instant;
    make?: string;
    marketValue?: number;
    model?: string;
    odometer?: number;
    optionsAdjustment?: number;
    orangeAlerts?: AlertDTO;
    originalMarketValue?: number;
    plateNumber?: string;
    rate?: RateDTO;
    rate1?: number;
    rate1Date?: Instant;
    redAlerts?: AlertDTO;
    registrationDate?: LocalDate;
    residualValue?: number;
    selected?: boolean;
    transmission?: string;
    validationDate?: Instant;
    version?: string;
    vin?: string;
}

export interface UserBasicDTO {
    id?: number;
    label?: string;
    login?: string;
    version?: number;
}

export interface UserFilter {
    creationInstantFrom?: Instant;
    creationInstantTo?: Instant;
    name?: string;
    role?: string;
}

export interface UserFormDTO {
    audit?: AuditDTO;
    authorities?: AuthorityDTO[];
    avatar?: FwFileDTO;
    blocked?: boolean;
    email?: string;
    firstName?: string;
    id?: number;
    imageUrl?: string;
    langKey?: string;
    lastName?: string;
    login?: string;
    resetKey?: string;
    resetLink?: string;
    validated?: boolean;
    validationKey?: string;
    validationLink?: string;
    version?: number;
}

export interface UserFormNewDTO {
    authorities?: string[];
    email?: string;
    firstName?: string;
    imageUrl?: string;
    lastName?: string;
    login?: string;
    password?: string;
    validated?: boolean;
}

export interface UserListDTO {
    audit?: AuditDTO;
    authorities?: string[];
    blocked?: boolean;
    email?: string;
    id?: number;
    imageUrl?: string;
    lastLogin?: Instant;
    login?: string;
    validated?: boolean;
}

export interface VehicleEquipmentDTO {
    description?: string;
    type?: EquipmentType;
}

export interface VehicleImportDTO {
    audit?: AuditDTO;
    errors?: number;
    file?: FwFileDTO;
    id?: number;
    importErrors?: ImportErrorDTO[];
    newMakes?: string[];
    processErrors?: ProcessErrorDTO[];
    processWarnings?: ProcessErrorDTO[];
    status?: VehicleImportStatus;
    vehicles?: number;
}

export interface VehicleImportFilter {
}

export interface VehicleImportListDTO {
    audit?: AuditDTO;
    errors?: number;
    id?: number;
    status?: VehicleImportStatus;
    vehicles?: number;
}

export function registerDefaultSerializers(config: ApinaConfig) {
    config.registerIdentitySerializer('Instant');
    config.registerIdentitySerializer('LocalDate');

    config.registerIdentitySerializer('AdjustmentType');
    config.registerIdentitySerializer('Alert');
    config.registerIdentitySerializer('EquipmentType');
    config.registerIdentitySerializer('ExportStatus');
    config.registerIdentitySerializer('ImportErrorType');
    config.registerIdentitySerializer('VehicleImportStatus');
    config.registerIdentitySerializer('VehicleState');
    config.registerIdentitySerializer('VehicleType');

    config.registerClassSerializer('AccountDTO', {
        'authorities': 'string[]',
        'avatar': 'FwFileDTO',
        'avatarUrl': 'string',
        'email': 'string',
        'firstName': 'string',
        'langKey': 'string',
        'lastName': 'string',
        'login': 'string',
        'loginUserOriginal': 'string',
        'validated': 'boolean',
        'version': 'number'
    });

    config.registerClassSerializer('AlertDTO', {
        'alert': 'Alert[]'
    });

    config.registerClassSerializer('AuditDTO', {
        'createdBy': 'string',
        'createdDate': 'Instant',
        'lastModifiedBy': 'string',
        'lastModifiedDate': 'Instant'
    });

    config.registerClassSerializer('AuthorityDTO', {
        'i18n': 'string',
        'name': 'string'
    });

    config.registerClassSerializer('BasicDTO', {
        'id': 'number',
        'label': 'string',
        'version': 'number'
    });

    config.registerClassSerializer('ClientDTO', {
        'b2bPercentage': 'number',
        'damagesPercentage': 'number',
        'expenses': 'number',
        'franchise': 'number',
        'id': 'number',
        'name': 'string',
        'optionsMinimumForAdjustment': 'number',
        'priceKmWhenDeficit': 'number',
        'priceKmWhenExcess': 'number',
        'vatRate': 'number'
    });

    config.registerClassSerializer('ClientFilter', {
        'name': 'string'
    });

    config.registerClassSerializer('ClientListDTO', {
        'id': 'number',
        'name': 'string'
    });

    config.registerClassSerializer('ExportFilter', {
        'client': 'BasicDTO',
        'clientId': 'number',
        'damagesMax': 'number',
        'damagesMin': 'number',
        'extensionDueDateFrom': 'LocalDate',
        'extensionDueDateFromMonth': 'number',
        'extensionDueDateFromYear': 'number',
        'extensionDueDateTo': 'LocalDate',
        'extensionDueDateToMonth': 'number',
        'extensionDueDateToYear': 'number',
        'finalRateMax': 'number',
        'finalRateMin': 'number',
        'finalRateVsVrAmountMax': 'number',
        'finalRateVsVrAmountMin': 'number',
        'finalRateVsVrPercentageMax': 'number',
        'finalRateVsVrPercentageMin': 'number',
        'insurance': 'boolean',
        'kilometersMax': 'number',
        'kilometersMin': 'number',
        'make': 'string',
        'model': 'string',
        'numberVo': 'string',
        'plateNumber': 'string',
        'platesAsList': 'string[]',
        'registrationDateFrom': 'LocalDate',
        'registrationDateTo': 'LocalDate',
        'residualValueWithTaxesMax': 'number',
        'residualValueWithTaxesMin': 'number',
        'valuationDateFrom': 'LocalDate',
        'valuationDateFromAsInstant': 'Instant',
        'valuationDateFromMonth': 'number',
        'valuationDateFromYear': 'number',
        'valuationDateTo': 'LocalDate',
        'valuationDateToAsInstant': 'Instant',
        'valuationDateToMonth': 'number',
        'valuationDateToYear': 'number',
        'vehicleState': 'VehicleState',
        'vehicleType': 'VehicleType',
        'version': 'string'
    });

    config.registerClassSerializer('FinalRateDTO', {
        'adjustmentType': 'AdjustmentType',
        'amountsId': 'number',
        'finalRate': 'number',
        'financialValue': 'number',
        'residualValueWithTaxes': 'number',
        'specialistAdjustment': 'number',
        'validated': 'boolean',
        'vatRate': 'number'
    });

    config.registerClassSerializer('FleetDTO', {
        'body': 'string',
        'color': 'string',
        'damages': 'number',
        'daysFromAvailableToSale': 'number',
        'depreciation': 'number',
        'discountAverage': 'number',
        'engineCapacity': 'number',
        'extensionDueDate': 'LocalDate',
        'finalRateVsFinancialValue': 'number',
        'financialValue': 'number',
        'futureSixMonthsStock': 'number',
        'insurance': 'boolean',
        'kilometersAverage': 'number',
        'numberVo': 'string',
        'optionsAdjustmentAmount': 'number',
        'optionsAdjustmentPercent': 'number',
        'pff': 'number',
        'physicalStock': 'number',
        'power': 'number',
        'priceAccessoriesWithTaxes': 'number',
        'priceExtras': 'number',
        'rateVsResidualValuePercentage': 'number',
        'registrationDate': 'LocalDate',
        'residualValue': 'number',
        'salePriceAverage': 'number',
        'sales': 'number',
        'type': 'VehicleType'
    });

    config.registerClassSerializer('FleetListDTO', {
        'b2cRate': 'number',
        'channel1': 'BasicDTO',
        'channel2': 'BasicDTO',
        'channel3': 'BasicDTO',
        'channel4': 'BasicDTO',
        'channelsSize': 'number',
        'clientId': 'number',
        'color': 'string',
        'doors': 'number',
        'estimatedOdometer': 'number',
        'financialValue': 'number',
        'fuel': 'string',
        'greenAlerts': 'AlertDTO',
        'lastValidationDate': 'Instant',
        'make': 'string',
        'marketValue': 'number',
        'model': 'string',
        'optionsAdjustment': 'number',
        'orangeAlerts': 'AlertDTO',
        'originalMarketValue': 'number',
        'plateNumber': 'string',
        'rate': 'RateDTO',
        'rateVsResidualValue': 'number',
        'redAlerts': 'AlertDTO',
        'residualValueWithTaxes': 'number',
        'selected': 'boolean',
        'transmission': 'string',
        'validationDate': 'Instant',
        'vatRate': 'number',
        'version': 'string',
        'vin': 'string'
    });

    config.registerClassSerializer('FwFileDTO', {
        'id': 'number',
        'name': 'string',
        'temporalFileName': 'string'
    });

    config.registerClassSerializer('ImportErrorDTO', {
        'error': 'string',
        'errorType': 'ImportErrorType',
        'id': 'number',
        'rowNumber': 'number'
    });

    config.registerClassSerializer('KeyAndPasswordDTO', {
        'key': 'string',
        'newPassword': 'string'
    });

    config.registerClassSerializer('LanguageDTO', {
        'code': 'string',
        'default': 'boolean',
        'name': 'string',
        'translation': 'string'
    });

    config.registerClassSerializer('NewVehicleImportDTO', {
        'client': 'BasicDTO',
        'identifyVehicles': 'boolean',
        'storedFile': 'FwFileDTO',
        'vehicleState': 'VehicleState'
    });

    config.registerClassSerializer('PasswordChangeDTO', {
        'currentPassword': 'string',
        'newPassword': 'string'
    });

    config.registerClassSerializer('ProcessErrorDTO', {
        'error': 'string',
        'vin': 'string'
    });

    config.registerClassSerializer('RateDTO', {
        'adjustmentType': 'AdjustmentType',
        'amountsId': 'number',
        'clientExpenses': 'number',
        'finalRate': 'number',
        'objetiveRate': 'number',
        'referenceRate': 'number',
        'specialistAdjustment': 'number',
        'validated': 'boolean'
    });

    config.registerClassSerializer('RateFilter', {
        'client': 'BasicDTO',
        'clientId': 'number',
        'damagesMax': 'number',
        'damagesMin': 'number',
        'extensionDueDateFrom': 'LocalDate',
        'extensionDueDateFromMonth': 'number',
        'extensionDueDateFromYear': 'number',
        'extensionDueDateTo': 'LocalDate',
        'extensionDueDateToMonth': 'number',
        'extensionDueDateToYear': 'number',
        'finalRateMax': 'number',
        'finalRateMin': 'number',
        'finalRateVsVrAmountMax': 'number',
        'finalRateVsVrAmountMin': 'number',
        'finalRateVsVrPercentageMax': 'number',
        'finalRateVsVrPercentageMin': 'number',
        'insurance': 'boolean',
        'kilometersMax': 'number',
        'kilometersMin': 'number',
        'make': 'string',
        'model': 'string',
        'numberVo': 'string',
        'plateNumber': 'string',
        'platesAsList': 'string[]',
        'registrationDateFrom': 'LocalDate',
        'registrationDateTo': 'LocalDate',
        'residualValueWithTaxesMax': 'number',
        'residualValueWithTaxesMin': 'number',
        'valuationDateFrom': 'LocalDate',
        'valuationDateFromAsInstant': 'Instant',
        'valuationDateFromMonth': 'number',
        'valuationDateFromYear': 'number',
        'valuationDateTo': 'LocalDate',
        'valuationDateToAsInstant': 'Instant',
        'valuationDateToMonth': 'number',
        'valuationDateToYear': 'number',
        'vehicleState': 'VehicleState',
        'vehicleType': 'VehicleType',
        'version': 'string'
    });

    config.registerClassSerializer('RateListDTO', {
        'date': 'Instant',
        'rate': 'number'
    });

    config.registerClassSerializer('StockDTO', {
        'body': 'string',
        'colorAdjustment': 'number',
        'damagesAdjustment': 'number',
        'daysFromAvailableToSale': 'number',
        'deducedKilometers': 'number',
        'differenceFinalRateSalesPriceAveragePercentage': 'number',
        'differenceLastRateFinalRateAmount': 'number',
        'differenceLastRateFinalRatePercentage': 'number',
        'differenceVrFinalRateAmount': 'number',
        'discountAverage': 'number',
        'futureSixMonthsStock': 'number',
        'kilometersAverage': 'number',
        'numberVO': 'string',
        'optionsAdjustment': 'number',
        'optionsAdjustmentAmount': 'number',
        'pff': 'number',
        'physicalStock': 'number',
        'power': 'number',
        'priceAccessoriesWithTaxes': 'number',
        'priceExtras': 'number',
        'salePriceAverage': 'number',
        'sales': 'number',
        'type': 'VehicleType'
    });

    config.registerClassSerializer('StockListDTO', {
        'color': 'string',
        'damages': 'number',
        'differenceVrFinalRatePercentage': 'number',
        'doors': 'number',
        'fuel': 'string',
        'greenAlerts': 'AlertDTO',
        'lastValidationDate': 'Instant',
        'make': 'string',
        'marketValue': 'number',
        'model': 'string',
        'odometer': 'number',
        'optionsAdjustment': 'number',
        'orangeAlerts': 'AlertDTO',
        'originalMarketValue': 'number',
        'plateNumber': 'string',
        'rate': 'RateDTO',
        'rate1': 'number',
        'rate1Date': 'Instant',
        'redAlerts': 'AlertDTO',
        'registrationDate': 'LocalDate',
        'residualValue': 'number',
        'selected': 'boolean',
        'transmission': 'string',
        'validationDate': 'Instant',
        'version': 'string',
        'vin': 'string'
    });

    config.registerClassSerializer('UserBasicDTO', {
        'id': 'number',
        'label': 'string',
        'login': 'string',
        'version': 'number'
    });

    config.registerClassSerializer('UserFilter', {
        'creationInstantFrom': 'Instant',
        'creationInstantTo': 'Instant',
        'name': 'string',
        'role': 'string'
    });

    config.registerClassSerializer('UserFormDTO', {
        'audit': 'AuditDTO',
        'authorities': 'AuthorityDTO[]',
        'avatar': 'FwFileDTO',
        'blocked': 'boolean',
        'email': 'string',
        'firstName': 'string',
        'id': 'number',
        'imageUrl': 'string',
        'langKey': 'string',
        'lastName': 'string',
        'login': 'string',
        'resetKey': 'string',
        'resetLink': 'string',
        'validated': 'boolean',
        'validationKey': 'string',
        'validationLink': 'string',
        'version': 'number'
    });

    config.registerClassSerializer('UserFormNewDTO', {
        'authorities': 'string[]',
        'email': 'string',
        'firstName': 'string',
        'imageUrl': 'string',
        'lastName': 'string',
        'login': 'string',
        'password': 'string',
        'validated': 'boolean'
    });

    config.registerClassSerializer('UserListDTO', {
        'audit': 'AuditDTO',
        'authorities': 'string[]',
        'blocked': 'boolean',
        'email': 'string',
        'id': 'number',
        'imageUrl': 'string',
        'lastLogin': 'Instant',
        'login': 'string',
        'validated': 'boolean'
    });

    config.registerClassSerializer('VehicleEquipmentDTO', {
        'description': 'string',
        'type': 'EquipmentType'
    });

    config.registerClassSerializer('VehicleImportDTO', {
        'audit': 'AuditDTO',
        'errors': 'number',
        'file': 'FwFileDTO',
        'id': 'number',
        'importErrors': 'ImportErrorDTO[]',
        'newMakes': 'string[]',
        'processErrors': 'ProcessErrorDTO[]',
        'processWarnings': 'ProcessErrorDTO[]',
        'status': 'VehicleImportStatus',
        'vehicles': 'number'
    });

    config.registerClassSerializer('VehicleImportFilter', {});

    config.registerClassSerializer('VehicleImportListDTO', {
        'audit': 'AuditDTO',
        'errors': 'number',
        'id': 'number',
        'status': 'VehicleImportStatus',
        'vehicles': 'number'
    });

}

export class ApinaConfig {

    /** Prefix added for all API calls */
    baseUrl: string = "";

    private serializers: SerializerMap = {
        any: identitySerializer,
        string: identitySerializer,
        number: identitySerializer,
        boolean: identitySerializer
    };

    constructor() {
        registerDefaultSerializers(this);
    }

    serialize(value: any, type: string): any {
        return this.lookupSerializer(type).serialize(value);
    }

    deserialize(value: any, type: string): any {
        return this.lookupSerializer(type).deserialize(value);
    }

    registerSerializer(name: string, serializer: Serializer) {
        this.serializers[name] = serializer;
    }

    registerEnumSerializer(name: string, enumObject: any) {
        this.registerSerializer(name, enumSerializer(enumObject));
    }

    registerClassSerializer(name: string, fields: any) {
        this.registerSerializer(name, this.classSerializer(fields));
    }

    registerIdentitySerializer(name: string) {
        this.registerSerializer(name, identitySerializer);
    }

    registerDiscriminatedUnionSerializer(name: string, discriminator: string, types: { [key: string]: string; }) {
        this.registerSerializer(name, this.discriminatedUnionSerializer(discriminator, types));
    }

    private classSerializer(fields: any): Serializer {
        function mapProperties(obj: any, propertyMapper: (value: any, type: string) => any) {
            if (obj === null || obj === undefined) {
                return obj;
            }

            const result: any = {};

            for (const name in fields) {
                if (fields.hasOwnProperty(name)) {
                    const value: any = obj[name];
                    const type: string = fields[name];
                    result[name] = propertyMapper(value, type);
                }
            }

            return result;
        }

        const serialize = this.serialize.bind(this);
        const deserialize = this.deserialize.bind(this);
        return {
            serialize(obj) {
                return mapProperties(obj, serialize);
            },
            deserialize(obj) {
                return mapProperties(obj, deserialize);
            }
        };
    }

    private discriminatedUnionSerializer(discriminatorProperty: string, types: { [key: string]: string; }): Serializer {
        const resolveSerializer = (localType: string) => {
            return this.lookupSerializer(types[localType]);
        };

        return {
            serialize(obj) {
                if (obj == null) return null;

                const localType = obj[discriminatorProperty];
                const result = resolveSerializer(localType).serialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            },
            deserialize(obj) {
                if (obj == null) return null;

                const localType = obj[discriminatorProperty];
                const result = resolveSerializer(localType).deserialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            }
        };
    }

    private lookupSerializer(type: string): Serializer {
        if (!type) throw new Error("no type given");

        if (type.indexOf('[]', type.length - 2) !== -1) { // type.endsWith('[]')
            const elementType = type.substring(0, type.length - 2);
            const elementSerializer = this.lookupSerializer(elementType);
            return arraySerializer(elementSerializer);
        }
        const serializer = this.serializers[type];
        if (serializer) {
            return serializer;
        } else {
            throw new Error(`could not find serializer for type '${type}'`);
        }
    }
}

function arraySerializer(elementSerializer: Serializer): Serializer {
    function safeMap(value: any[], mapper: (a: any) => any) {
        if (!value)
            return value;
        else
            return value.map(mapper);
    }

    return {
        serialize(value) {
            return safeMap(value, elementSerializer.serialize.bind(elementSerializer));
        },
        deserialize(value) {
            return safeMap(value, elementSerializer.deserialize.bind(elementSerializer));
        }
    }
}

export interface RequestData {
    uriTemplate: string;
    method: string;
    pathVariables?: any;
    requestParams?: any;
    requestBody?: any;
    responseType?: string;
}

export interface Serializer {
    serialize(o: any): any;
    deserialize(o: any): any;
}

const identitySerializer: Serializer = {
    serialize(o) {
        return o;
    },
    deserialize(o) {
        return o;
    }
};

function enumSerializer(enumObject: any): Serializer {
    return {
        serialize(o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        },
        deserialize(o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        }
    }
}

interface SerializerMap {
    [name: string]: Serializer;
}

export abstract class ApinaEndpointContext {

    constructor(protected config: ApinaConfig) {
    }

    abstract request(data: RequestData): Observable<any>

    serialize(value: any, type: string): any {
        return this.config.serialize(value, type);
    }

    deserialize(value: any, type: string): any {
        return this.config.deserialize(value, type);
    }

    buildUrl(uriTemplate: String, pathVariables: any): string {
        return this.config.baseUrl + uriTemplate.replace(/{([^}]+)}/g, (match, name) => pathVariables[name]);
    }
}

@Injectable()
export class DefaultApinaEndpointContext extends ApinaEndpointContext {

    constructor(private httpClient: HttpClient, config: ApinaConfig) {
        super(config);
    }

    request(data: RequestData): Observable<any> {
        const url = this.buildUrl(data.uriTemplate, data.pathVariables);

        const requestParams = data.requestParams;
        let params: HttpParams | undefined = undefined;
        if (requestParams != null) {
            const filteredParams: { [key: string]: any }  = {};
            for (const key of Object.keys(requestParams)) {
                const value = requestParams[key];
                if (value != null)
                    filteredParams[key] = value;
            }

            params = new HttpParams({fromObject: filteredParams});
        }


        return this.httpClient.request(data.method, url, { params: params, body: data.requestBody })
            .pipe(map(r => data.responseType ? this.config.deserialize(r, data.responseType) : r));
    }
}

@Injectable( {providedIn: 'root'} )
export class AccountResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    changePassword(passwordChangeDto: PasswordChangeDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/account/change-password',
            'method': 'POST',
            'requestBody': this.context.serialize(passwordChangeDto, 'PasswordChangeDTO')
        });
    }

    changePasswordUrl(passwordChangeDto: PasswordChangeDTO): string {
        return this.context.buildUrl('/account/change-password' , null);
    }

    finishPasswordReset(keyAndPassword: KeyAndPasswordDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/account/reset-password/finish',
            'method': 'POST',
            'requestBody': this.context.serialize(keyAndPassword, 'KeyAndPasswordDTO')
        });
    }

    finishPasswordResetUrl(keyAndPassword: KeyAndPasswordDTO): string {
        return this.context.buildUrl('/account/reset-password/finish' , null);
    }

    getAccount(): Observable<AccountDTO> {
        return this.context.request({
            'uriTemplate': '/account',
            'method': 'GET',
            'responseType': 'AccountDTO'
        });
    }

    getAccountUrl(): string {
        return this.context.buildUrl('/account' , null);
    }

    isAuthenticated(): Observable<string> {
        return this.context.request({
            'uriTemplate': '/authenticate',
            'method': 'GET',
            'responseType': 'string'
        });
    }

    isAuthenticatedUrl(): string {
        return this.context.buildUrl('/authenticate' , null);
    }

    registerAccount(userFormNewDTO: UserFormNewDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/register',
            'method': 'POST',
            'requestBody': this.context.serialize(userFormNewDTO, 'UserFormNewDTO')
        });
    }

    registerAccountUrl(userFormNewDTO: UserFormNewDTO): string {
        return this.context.buildUrl('/register' , null);
    }

    requestPasswordReset(mail: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/account/reset-password/init',
            'method': 'POST',
            'requestBody': this.context.serialize(mail, 'string')
        });
    }

    requestPasswordResetUrl(mail: string): string {
        return this.context.buildUrl('/account/reset-password/init' , null);
    }

    saveAccount(accountDTO: AccountDTO): Observable<AccountDTO> {
        return this.context.request({
            'uriTemplate': '/account',
            'method': 'POST',
            'requestBody': this.context.serialize(accountDTO, 'AccountDTO'),
            'responseType': 'AccountDTO'
        });
    }

    saveAccountUrl(accountDTO: AccountDTO): string {
        return this.context.buildUrl('/account' , null);
    }

    validateAccount(key: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/validate',
            'method': 'GET',
            'requestParams': {
                'key': this.context.serialize(key, 'string')
            }
        });
    }

    validateAccountUrl(key: string): string {
        return this.context.buildUrl('/validate' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class ClientResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(clientDTO: ClientDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/clients',
            'method': 'POST',
            'requestBody': this.context.serialize(clientDTO, 'ClientDTO'),
            'responseType': 'number'
        });
    }

    createUrl(clientDTO: ClientDTO): string {
        return this.context.buildUrl('/clients' , null);
    }

    findAll(filter: ClientFilter, pageable: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/clients',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'ClientFilter'),
                'pageable': this.context.serialize(pageable, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: ClientFilter, pageable: Pageable): string {
        return this.context.buildUrl('/clients' , null);
    }

    findAllBasic(): Observable<BasicDTO[]> {
        return this.context.request({
            'uriTemplate': '/clients/all-basic',
            'method': 'GET',
            'responseType': 'BasicDTO[]'
        });
    }

    findAllBasicUrl(): string {
        return this.context.buildUrl('/clients/all-basic' , null);
    }

    findAllChannelsByClientId(clientId: number): Observable<BasicDTO[]> {
        return this.context.request({
            'uriTemplate': '/clients/channels/{clientId}',
            'method': 'GET',
            'pathVariables': {
                'clientId': this.context.serialize(clientId, 'number')
            },
            'responseType': 'BasicDTO[]'
        });
    }

    findAllChannelsByClientIdUrl(clientId: number): string {
        return this.context.buildUrl('/clients/channels/{clientId}' , {
            'clientId': this.context.serialize(clientId, 'number')
        });
    }

    get(id: number): Observable<ClientDTO> {
        return this.context.request({
            'uriTemplate': '/clients/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'ClientDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/clients/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    update(id: number, clientDTO: ClientDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/clients/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(clientDTO, 'ClientDTO')
        });
    }

    updateUrl(id: number, clientDTO: ClientDTO): string {
        return this.context.buildUrl('/clients/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class EnumResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    adjustmentTypes(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/enum/adjustmenttypes',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    adjustmentTypesUrl(): string {
        return this.context.buildUrl('/enum/adjustmenttypes' , null);
    }

    alerts(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/enum/alerts',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    alertsUrl(): string {
        return this.context.buildUrl('/enum/alerts' , null);
    }

    equipmentTypes(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/enum/equipmenttypes',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    equipmentTypesUrl(): string {
        return this.context.buildUrl('/enum/equipmenttypes' , null);
    }

    getBooleans(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/enum/booleans',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    getBooleansUrl(): string {
        return this.context.buildUrl('/enum/booleans' , null);
    }

    importStatuses(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/enum/importStatuses',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    importStatusesUrl(): string {
        return this.context.buildUrl('/enum/importStatuses' , null);
    }

    vehicleStates(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/enum/vehiclestates',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    vehicleStatesUrl(): string {
        return this.context.buildUrl('/enum/vehiclestates' , null);
    }

    vehicleTypes(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/enum/vehicletypes',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    vehicleTypesUrl(): string {
        return this.context.buildUrl('/enum/vehicletypes' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class FicheroResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    downloadTemporalFile(temporalFileName: string, fileName: string, fileCodification: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/files/{temporalFileName}',
            'method': 'GET',
            'pathVariables': {
                'temporalFileName': this.context.serialize(temporalFileName, 'string')
            },
            'requestParams': {
                'fileName': this.context.serialize(fileName, 'string'),
                'fileCodification': this.context.serialize(fileCodification, 'string')
            }
        });
    }

    downloadTemporalFileUrl(temporalFileName: string, fileName: string, fileCodification: string): string {
        return this.context.buildUrl('/files/{temporalFileName}' , {
            'temporalFileName': this.context.serialize(temporalFileName, 'string')
        });
    }

    saveTemporalFile(): Observable<string> {
        return this.context.request({
            'uriTemplate': '/files/temporal',
            'method': 'POST',
            'responseType': 'string'
        });
    }

    saveTemporalFileUrl(): string {
        return this.context.buildUrl('/files/temporal' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class LanguageResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    getCurrentLanguage(): Observable<LanguageDTO> {
        return this.context.request({
            'uriTemplate': '/languages/current',
            'method': 'GET',
            'responseType': 'LanguageDTO'
        });
    }

    getCurrentLanguageUrl(): string {
        return this.context.buildUrl('/languages/current' , null);
    }

    getLanguages(): Observable<LanguageDTO[]> {
        return this.context.request({
            'uriTemplate': '/languages',
            'method': 'GET',
            'responseType': 'LanguageDTO[]'
        });
    }

    getLanguagesUrl(): string {
        return this.context.buildUrl('/languages' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class RateResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    getEquipments(vin: string, type: EquipmentType): Observable<VehicleEquipmentDTO[]> {
        return this.context.request({
            'uriTemplate': '/rate/equipments/{vin}',
            'method': 'GET',
            'pathVariables': {
                'vin': this.context.serialize(vin, 'string')
            },
            'requestParams': {
                'type': this.context.serialize(type, 'EquipmentType')
            },
            'responseType': 'VehicleEquipmentDTO[]'
        });
    }

    getEquipmentsUrl(vin: string, type: EquipmentType): string {
        return this.context.buildUrl('/rate/equipments/{vin}' , {
            'vin': this.context.serialize(vin, 'string')
        });
    }

    getFleet(pageable: Pageable, filter: RateFilter): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/rate/fleet',
            'method': 'GET',
            'requestParams': {
                'pageable': this.context.serialize(pageable, 'Pageable'),
                'filter': this.context.serialize(filter, 'RateFilter')
            },
            'responseType': 'Page'
        });
    }

    getFleetUrl(pageable: Pageable, filter: RateFilter): string {
        return this.context.buildUrl('/rate/fleet' , null);
    }

    getFleetDetails(id: number): Observable<FleetDTO> {
        return this.context.request({
            'uriTemplate': '/rate/fleet/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'FleetDTO'
        });
    }

    getFleetDetailsUrl(id: number): string {
        return this.context.buildUrl('/rate/fleet/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getMakes(): Observable<string[]> {
        return this.context.request({
            'uriTemplate': '/rate/makes',
            'method': 'GET',
            'responseType': 'string[]'
        });
    }

    getMakesUrl(): string {
        return this.context.buildUrl('/rate/makes' , null);
    }

    getModels(make: string): Observable<string[]> {
        return this.context.request({
            'uriTemplate': '/rate/models',
            'method': 'GET',
            'requestParams': {
                'make': this.context.serialize(make, 'string')
            },
            'responseType': 'string[]'
        });
    }

    getModelsUrl(make: string): string {
        return this.context.buildUrl('/rate/models' , null);
    }

    getRates(vin: string): Observable<RateListDTO[]> {
        return this.context.request({
            'uriTemplate': '/rate/rates/{vin}',
            'method': 'GET',
            'pathVariables': {
                'vin': this.context.serialize(vin, 'string')
            },
            'responseType': 'RateListDTO[]'
        });
    }

    getRatesUrl(vin: string): string {
        return this.context.buildUrl('/rate/rates/{vin}' , {
            'vin': this.context.serialize(vin, 'string')
        });
    }

    getStock(pageable: Pageable, filter: RateFilter): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/rate/stock',
            'method': 'GET',
            'requestParams': {
                'pageable': this.context.serialize(pageable, 'Pageable'),
                'filter': this.context.serialize(filter, 'RateFilter')
            },
            'responseType': 'Page'
        });
    }

    getStockUrl(pageable: Pageable, filter: RateFilter): string {
        return this.context.buildUrl('/rate/stock' , null);
    }

    getStockDetails(id: number): Observable<StockDTO> {
        return this.context.request({
            'uriTemplate': '/rate/stock/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'StockDTO'
        });
    }

    getStockDetailsUrl(id: number): string {
        return this.context.buildUrl('/rate/stock/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    saveRate(rateDTO: FinalRateDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/rate',
            'method': 'POST',
            'requestBody': this.context.serialize(rateDTO, 'FinalRateDTO')
        });
    }

    saveRateUrl(rateDTO: FinalRateDTO): string {
        return this.context.buildUrl('/rate' , null);
    }

    setChannel(amountsId: number, channelId: number, channelNumber: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/rate/channel/{amountsId}/{channelId}/{channelNumber}',
            'method': 'POST',
            'pathVariables': {
                'amountsId': this.context.serialize(amountsId, 'number')
            },
            'requestParams': {
                'channelId': this.context.serialize(channelId, 'number'),
                'channelNumber': this.context.serialize(channelNumber, 'number')
            }
        });
    }

    setChannelUrl(amountsId: number, channelId: number, channelNumber: number): string {
        return this.context.buildUrl('/rate/channel/{amountsId}/{channelId}/{channelNumber}' , {
            'amountsId': this.context.serialize(amountsId, 'number')
        });
    }

    updateMarketValue(id: number, marketValue: number): Observable<RateDTO> {
        return this.context.request({
            'uriTemplate': '/rate/marketValue/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestParams': {
                'marketValue': this.context.serialize(marketValue, 'number')
            },
            'responseType': 'RateDTO'
        });
    }

    updateMarketValueUrl(id: number, marketValue: number): string {
        return this.context.buildUrl('/rate/marketValue/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    updateRates(rates: FinalRateDTO[]): Observable<void> {
        return this.context.request({
            'uriTemplate': '/rate/group',
            'method': 'POST',
            'requestBody': this.context.serialize(rates, 'FinalRateDTO[]')
        });
    }

    updateRatesUrl(rates: FinalRateDTO[]): string {
        return this.context.buildUrl('/rate/group' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class StarfleetExportResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    download(exportId: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/starfleet-export/download/{exportId}',
            'method': 'GET',
            'pathVariables': {
                'exportId': this.context.serialize(exportId, 'number')
            }
        });
    }

    downloadUrl(exportId: number): string {
        return this.context.buildUrl('/starfleet-export/download/{exportId}' , {
            'exportId': this.context.serialize(exportId, 'number')
        });
    }

    export(filter: ExportFilter): Observable<number> {
        return this.context.request({
            'uriTemplate': '/starfleet-export/create',
            'method': 'POST',
            'requestParams': {
                'filter': this.context.serialize(filter, 'ExportFilter')
            },
            'responseType': 'number'
        });
    }

    exportUrl(filter: ExportFilter): string {
        return this.context.buildUrl('/starfleet-export/create' , null);
    }

    status(exportId: number): Observable<ExportStatus> {
        return this.context.request({
            'uriTemplate': '/starfleet-export/status/{exportId}',
            'method': 'GET',
            'pathVariables': {
                'exportId': this.context.serialize(exportId, 'number')
            },
            'responseType': 'ExportStatus'
        });
    }

    statusUrl(exportId: number): string {
        return this.context.buildUrl('/starfleet-export/status/{exportId}' , {
            'exportId': this.context.serialize(exportId, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class UserResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(userFormDTO: UserFormDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/users',
            'method': 'POST',
            'requestBody': this.context.serialize(userFormDTO, 'UserFormDTO'),
            'responseType': 'number'
        });
    }

    createUrl(userFormDTO: UserFormDTO): string {
        return this.context.buildUrl('/users' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/users/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filtro: UserFilter, pageable: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/users',
            'method': 'GET',
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'UserFilter'),
                'pageable': this.context.serialize(pageable, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filtro: UserFilter, pageable: Pageable): string {
        return this.context.buildUrl('/users' , null);
    }

    findAllOrdenadosPorLogin(): Observable<UserBasicDTO[]> {
        return this.context.request({
            'uriTemplate': '/users/todos',
            'method': 'GET',
            'responseType': 'UserBasicDTO[]'
        });
    }

    findAllOrdenadosPorLoginUrl(): string {
        return this.context.buildUrl('/users/todos' , null);
    }

    get(id: number): Observable<UserFormDTO> {
        return this.context.request({
            'uriTemplate': '/users/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'UserFormDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/users/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getAuthorities(): Observable<AuthorityDTO[]> {
        return this.context.request({
            'uriTemplate': '/users/authorities',
            'method': 'GET',
            'responseType': 'AuthorityDTO[]'
        });
    }

    getAuthoritiesUrl(): string {
        return this.context.buildUrl('/users/authorities' , null);
    }

    getAvatar(login: string, avatarId: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/{login}/{avatarId}/avatar.jpg',
            'method': 'GET',
            'pathVariables': {
                'login': this.context.serialize(login, 'string'),
                'avatarId': this.context.serialize(avatarId, 'string')
            }
        });
    }

    getAvatarUrl(login: string, avatarId: string): string {
        return this.context.buildUrl('/users/{login}/{avatarId}/avatar.jpg' , {
            'login': this.context.serialize(login, 'string'),
            'avatarId': this.context.serialize(avatarId, 'string')
        });
    }

    update(id: number, userFormDTO: UserFormDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(userFormDTO, 'UserFormDTO')
        });
    }

    updateUrl(id: number, userFormDTO: UserFormDTO): string {
        return this.context.buildUrl('/users/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class VehicleExportResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    download(exportId: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/vehicle-export/download/{exportId}',
            'method': 'GET',
            'pathVariables': {
                'exportId': this.context.serialize(exportId, 'number')
            }
        });
    }

    downloadUrl(exportId: number): string {
        return this.context.buildUrl('/vehicle-export/download/{exportId}' , {
            'exportId': this.context.serialize(exportId, 'number')
        });
    }

    export(filter: ExportFilter): Observable<number> {
        return this.context.request({
            'uriTemplate': '/vehicle-export/create',
            'method': 'POST',
            'requestParams': {
                'filter': this.context.serialize(filter, 'ExportFilter')
            },
            'responseType': 'number'
        });
    }

    exportUrl(filter: ExportFilter): string {
        return this.context.buildUrl('/vehicle-export/create' , null);
    }

    status(exportId: number): Observable<ExportStatus> {
        return this.context.request({
            'uriTemplate': '/vehicle-export/status/{exportId}',
            'method': 'GET',
            'pathVariables': {
                'exportId': this.context.serialize(exportId, 'number')
            },
            'responseType': 'ExportStatus'
        });
    }

    statusUrl(exportId: number): string {
        return this.context.buildUrl('/vehicle-export/status/{exportId}' , {
            'exportId': this.context.serialize(exportId, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class VehicleImportResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    findAll(filter: VehicleImportFilter, pageable: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/vehicle-import',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'VehicleImportFilter'),
                'pageable': this.context.serialize(pageable, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: VehicleImportFilter, pageable: Pageable): string {
        return this.context.buildUrl('/vehicle-import' , null);
    }

    get(id: number): Observable<VehicleImportDTO> {
        return this.context.request({
            'uriTemplate': '/vehicle-import/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'VehicleImportDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/vehicle-import/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getFile(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/vehicle-import/file/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    getFileUrl(id: number): string {
        return this.context.buildUrl('/vehicle-import/file/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    importVO(dto: NewVehicleImportDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/vehicle-import',
            'method': 'POST',
            'requestBody': this.context.serialize(dto, 'NewVehicleImportDTO'),
            'responseType': 'number'
        });
    }

    importVOUrl(dto: NewVehicleImportDTO): string {
        return this.context.buildUrl('/vehicle-import' , null);
    }

}


export function apinaConfigFactory() {
    return new ApinaConfig();
}

@NgModule({
    imports: [HttpClientModule],
    providers: [
        { provide: ApinaEndpointContext, useClass: DefaultApinaEndpointContext },
        { provide: ApinaConfig, useFactory: apinaConfigFactory }
    ]
})
export class ApinaModule {}
