import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {LanguageService} from 'fw-core';

export class EnumInterceptor implements HttpInterceptor {

    constructor(private languageService: LanguageService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // si se saca una nueva versión de la aplicación se vuelven a descargar los enumerados
        if (request.url.startsWith('/api/enum/')) {
            const version = environment.VERSION;
            let params = request.params.set('v', version);

            const lang = this.languageService.getCurrentLanguageLocaleStorage();
            if (lang) {
                params = params.set('lang', lang);
            }
            const cloneReq = request.clone({params});
            return next.handle(cloneReq);
        }

        return next.handle(request);
    }

}
