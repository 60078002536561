
export const URL_AVATAR_DEFAULT = 'content/img/avatars/avatar-default.png';
export const URL_IMAGE_DEFAULT = 'content/img/default.jpg';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_READONLY = 'ROLE_READONLY';

export const LOGIN_STATE = '/auth/login';

export const MIN_PASSWD_LENGTH = 8;
