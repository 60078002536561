import {Component, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router} from '@angular/router';

import {Title} from '@angular/platform-browser';
import {AlertErrorService, ServiceWorkerService} from 'fw-core';
import {environment} from '../environments/environment';
import {ViewportScroller} from '@angular/common';
import {filter, pairwise} from 'rxjs/operators';

@Component({
    selector: 'app',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    constructor(private titleService: Title, private serviceWorkerService: ServiceWorkerService,
                private router: Router, private alertErrorService: AlertErrorService,
                private viewportScroller: ViewportScroller) {
    }

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) { // TODO hacer configurable
        let title: string = routeSnapshot.data && routeSnapshot.data['pageTitle'] ? routeSnapshot.data['pageTitle'] + ' | Pricing Tool' : 'Pricing Tool';
        if (routeSnapshot.firstChild) {
            title = this.getPageTitle(routeSnapshot.firstChild) || title;
        }
        return title;
    }

    ngOnInit() {
        this.serviceWorkerService.checkForUpdates(environment.production);
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.titleService.setTitle(this.getPageTitle(this.router.routerState.snapshot.root));
            }

            if (event instanceof NavigationStart) {
                const isNotBackButtonNavigation = event.navigationTrigger === 'imperative';
                if (isNotBackButtonNavigation) {
                    this.alertErrorService.closeAlert();
                }
            }
        });

        this.router.events.pipe(filter((evt: any) => evt instanceof NavigationEnd), pairwise())
            .subscribe((events: NavigationEnd[]) => {
                if (events[0].urlAfterRedirects !== events[1].urlAfterRedirects) {
                    this.viewportScroller.scrollToPosition([0, 0]);
                }
            });
    }

}
