export const USER_MENU = [
    {
        name: 'Inicio',
        url: '/home',
        icon: 'fas fa-home'
    },
    {
        name: 'Importación',
        url: '/import/list',
        icon: 'fas fa-file-upload'
    },
    {
        name: 'Valoración',
        url: '/rate',
        icon: 'fas fa-euro-sign',
        children: [
            {
                name: 'Flota viva',
                url: '/rate/fleet',
                icon: 'fas fa-car'
            },
            {
                name: 'Stock',
                url: '/rate/stock',
                icon: 'fas fa-warehouse'
            }
        ]
    },
    {
        name: 'Exportación',
        url: '/export/export',
        icon: 'fas fa-file-download'
    }
];
