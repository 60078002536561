import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Principal} from 'app/security/principal.service';
import {LoginService} from 'app/auth/login/login.service';
import {LOGIN_STATE, ROLE_ADMIN, ROLE_USER, URL_AVATAR_DEFAULT} from 'app/constants';
import {AccountDTO, UserResourceEndpoint} from 'app/apina/apina-api';
import {ADMIN_MENU} from 'app/layout/menu/menu-admin';
import {USER_MENU} from 'app/layout/menu/menu-user';
import { IBreadcrumb, FwBreadcrumbsService } from 'fw-core';
import { READ_ONLY_MENU } from '../menu/menu-readonly';

@Component({
    templateUrl: './default-layout.component.html',
    styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent implements OnInit {
    navItems: any;
    version: string;
    account: AccountDTO;
    mostrarSidebar: any = false;
    crumbs: IBreadcrumb[];
    loginState = LOGIN_STATE;

    constructor(@Inject(LOCALE_ID) protected localeId: string,
                private loginService: LoginService,
                private principal: Principal,
                private router: Router,
                private crumbsService: FwBreadcrumbsService,
                private userResource: UserResourceEndpoint) {
        this.version = environment.VERSION;
    }

    ngOnInit() {
        this.crumbsService.crumbs$.subscribe(response => {
            this.crumbs = response;
        });

        this.principal.state$.subscribe(datos => {
            if (datos) {
                this.account = datos;
                this.navItems = this.getApartados();
                this.mostrarSidebar = 'lg';
            } else {
                this.account = datos;
                this.mostrarSidebar = false;
                this.navItems = this.getApartados();
            }
        });
    }

    getApartados() {
        if (this.isAuthenticated()) {
            if (this.account.authorities.includes(ROLE_ADMIN)) {
                return ADMIN_MENU;
            } else if (this.account.authorities.includes(ROLE_USER)) {
                return USER_MENU;
            } else {
                return READ_ONLY_MENU;
            }
        } else {
            return null;
        }
    }

    isAuthenticated() {
        return this.principal.isAuthenticated();
    }

    logout() {
        this.loginService.logout().subscribe(() => {
            this.router.navigate(['']);
        });
    }

    getImageUrl() {
        return this.isAuthenticated() && this.account.avatar && this.account.avatar.id ?
            this.userResource.getAvatarUrl(this.account.login, this.account.avatar.id.toString()) : URL_AVATAR_DEFAULT;
    }

}
