import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DefaultLayoutComponent} from 'app/layout/default-layout/default-layout.component';
import {ErrorComponent} from 'app/layout/error/error.component';
import {UserRouteAccessService} from 'app/security/user-route-access-service';
import {ROLE_ADMIN, ROLE_READONLY, ROLE_USER} from 'app/constants';

/**
 *  Cada página pública debe tener su propio módulo lazy optimizado para conseguir el menos tamaño de
 *  build posible. En el caso de las páginas de administración o que necesitan que el usuario esté autenticado,
 *  podemos agruparlas por simplicidad ya que los tiempos de carga iniciales no son tan importantes.
 */
@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: '',
                    component: DefaultLayoutComponent,
                    children: [
                        {
                            path: '',
                            redirectTo: 'auth/login',
                            pathMatch: 'full'
                        },
                        {
                            path: 'home',
                            loadChildren: () => import('./public/home/home.module').then(m => m.HomeModule),
                            data: {
                                authorities: [ROLE_USER, ROLE_ADMIN, ROLE_READONLY]
                            },
                            canActivate: [UserRouteAccessService]
                        },
                        {
                            path: 'admin',
                            loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
                            data: {
                                authorities: [ROLE_ADMIN]
                            },
                            canActivateChild: [UserRouteAccessService],
                        },
                        {
                            path: 'account',
                            loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
                            data: {
                                authorities: [ROLE_USER, ROLE_ADMIN]
                            },
                            canActivate: [UserRouteAccessService],
                        },
                        {
                            path: 'import',
                            loadChildren: () => import('./import/import.module').then( m => m.ImportModule),
                            data: {
                                authorities: [ROLE_USER]
                            },
                            canActivate: [UserRouteAccessService]
                        },
                        {
                            path: 'rate',
                            loadChildren: () => import('./rate/rate.module').then(m => m.RateModule),
                            data: {
                                authorities: [ROLE_USER]
                            },
                            canActivate: [UserRouteAccessService]
                        },
                        {
                            path: 'export',
                            loadChildren: () => import('./export/export.module').then(m => m.ExportModule),
                            data: {
                                authorities: [ROLE_USER, ROLE_READONLY]
                            },
                            canActivate: [UserRouteAccessService]
                        },
                        {
                            path: 'auth',
                            loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
                        },
                        {
                            path: '**', component: ErrorComponent
                        }
                    ]
                },
            ],
            {
                useHash: false, enableTracing: false, onSameUrlNavigation: 'reload'
            }
        )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {

}
