export const ADMIN_MENU = [
    {
        name: 'Inicio',
        url: '/home',
        icon: 'fas fa-home'
    },
    {
        title: true,
        name: 'Administración'
    },
    {
        name: 'Usuarios',
        url: '/admin/users',
        icon: 'fas fa-users'
    },
    {
        name: 'Clientes',
        url: '/admin/clients',
        icon: 'fas fa-building'
    }
];
